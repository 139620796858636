import React, { useEffect, useRef, useState } from "react";
import styles from './hero.module.css';
export default function Hero({}) {
    const [title, setTitle] = useState('Fueling Discovery Summer Giveaway!');
    const [body, setBody] = useState('');
    const [button_title, setButtonTitle] = useState('Enter to Win');

    const videoRef= useRef<HTMLVideoElement>();
    const [playbackRate, setPlaybackRate] = useState(0.75);

    useEffect(() => {
        if (videoRef && videoRef.current && videoRef.current.playbackRate) {
            videoRef.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    const setPlayBack = (e: any) => {
        setPlaybackRate(e.target.value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.container_inner}>
                <div className={styles.video_wrapper}>
                    <video ref={videoRef} className={styles.video} width="320" height="240" autoPlay loop muted>
                        <source src={`https://operation-give-thanks.s3.amazonaws.com/website/default_video.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className={styles.content_gradient_overlay}></div>


                <div className={styles.content_container} style={{ textAlign: 'center' }}>
                    <h1 className={styles.title}>Fueling Discovery Summer Giveaway!</h1>
                    <div className={styles.body}>{body}</div>
                    <div onClick={() => { 
                        window.location.href = '/win';
                     }} className={styles.button}>{button_title}</div>
                </div>

            </div>

            
        </div>
    );
}
