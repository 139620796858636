export const operationGiveThanks = `
# Operation Give Thanks
<div id="video"> https://www.youtube.com/embed/2T7Je8p_Gco </div>
</br>
### Join with Kwik Fill for Operation Give Thanks to support military families in Kwik Fill Country.
</br>
In 2023 the combination of customers’ direct donations, merchandise sales, vendor partner donations, and a portion of fuel sales totaled $243,429.95.
</br></br>
Operation Give Thanks is a company-and customer-supported program designed to aid active-duty service members, veterans, and their families using various armed forces related charities in Kwik Fill Country.
</br></br>
<a href="https://www.operationgivethanks.com" id="button">Learn More</a>
</br></br>
<img id="image-full" src="/assets/secondary/givethanks.jpeg" />
</br>
</br>
</br>
`