import React from "react";
import styles from './offers.module.css';
import withRouter from "@/components/functional-hook-wrapper";

export class HomeOffers extends React.Component {
    state = {
        title: 'Kwik Fill',
        body: 'Fueling Discovery Summer Giveaway!',
        button_title: 'Enter Now',
    }
    
    router: any;

    constructor(props: any) {
        super(props);

        this.router = props.router;
    }

    render() {
        return (
            <div className={styles.container} onClick={() => { this.router.navigate('/win') }}>
                <div className={styles.container_inner} style={{backgroundImage: `url(/assets/giveaway/2024/summer/summer_giveaway.png)`}}>
                    {/* <div className={styles.button}>{this.state.button_title}</div> */}
                </div>
            </div>
        );
    }
}

export default withRouter(HomeOffers);
